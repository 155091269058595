// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-thanks-tsx": () => import("./../../../src/pages/contact/thanks.tsx" /* webpackChunkName: "component---src-pages-contact-thanks-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-game-development-art-production-tsx": () => import("./../../../src/pages/game-development/art-production.tsx" /* webpackChunkName: "component---src-pages-game-development-art-production-tsx" */),
  "component---src-pages-game-development-continuous-development-tsx": () => import("./../../../src/pages/game-development/continuous-development.tsx" /* webpackChunkName: "component---src-pages-game-development-continuous-development-tsx" */),
  "component---src-pages-game-development-game-design-tsx": () => import("./../../../src/pages/game-development/game-design.tsx" /* webpackChunkName: "component---src-pages-game-development-game-design-tsx" */),
  "component---src-pages-game-development-game-programming-tsx": () => import("./../../../src/pages/game-development/game-programming.tsx" /* webpackChunkName: "component---src-pages-game-development-game-programming-tsx" */),
  "component---src-pages-game-development-tsx": () => import("./../../../src/pages/game-development.tsx" /* webpackChunkName: "component---src-pages-game-development-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-policy-privacy-tsx": () => import("./../../../src/pages/policy/privacy.tsx" /* webpackChunkName: "component---src-pages-policy-privacy-tsx" */),
  "component---src-pages-policy-terms-tsx": () => import("./../../../src/pages/policy/terms.tsx" /* webpackChunkName: "component---src-pages-policy-terms-tsx" */),
  "component---src-pages-software-development-native-development-tsx": () => import("./../../../src/pages/software-development/native-development.tsx" /* webpackChunkName: "component---src-pages-software-development-native-development-tsx" */),
  "component---src-pages-software-development-saas-development-tsx": () => import("./../../../src/pages/software-development/saas-development.tsx" /* webpackChunkName: "component---src-pages-software-development-saas-development-tsx" */),
  "component---src-pages-software-development-tsx": () => import("./../../../src/pages/software-development.tsx" /* webpackChunkName: "component---src-pages-software-development-tsx" */),
  "component---src-pages-software-development-web-development-tsx": () => import("./../../../src/pages/software-development/web-development.tsx" /* webpackChunkName: "component---src-pages-software-development-web-development-tsx" */),
  "component---src-pages-work-booom-casino-tsx": () => import("./../../../src/pages/work/booom-casino.tsx" /* webpackChunkName: "component---src-pages-work-booom-casino-tsx" */),
  "component---src-pages-work-crystal-crusher-tsx": () => import("./../../../src/pages/work/crystal-crusher.tsx" /* webpackChunkName: "component---src-pages-work-crystal-crusher-tsx" */),
  "component---src-pages-work-hyper-tap-tsx": () => import("./../../../src/pages/work/hyper-tap.tsx" /* webpackChunkName: "component---src-pages-work-hyper-tap-tsx" */),
  "component---src-pages-work-recipe-rescue-2-tsx": () => import("./../../../src/pages/work/recipe-rescue-2.tsx" /* webpackChunkName: "component---src-pages-work-recipe-rescue-2-tsx" */),
  "component---src-pages-work-recipe-rescue-fruit-blast-tsx": () => import("./../../../src/pages/work/recipe-rescue-fruit-blast.tsx" /* webpackChunkName: "component---src-pages-work-recipe-rescue-fruit-blast-tsx" */),
  "component---src-pages-work-recipe-rescue-tsx": () => import("./../../../src/pages/work/recipe-rescue.tsx" /* webpackChunkName: "component---src-pages-work-recipe-rescue-tsx" */),
  "component---src-pages-work-slot-universe-tsx": () => import("./../../../src/pages/work/slot-universe.tsx" /* webpackChunkName: "component---src-pages-work-slot-universe-tsx" */),
  "component---src-pages-work-super-gem-heroes-tsx": () => import("./../../../src/pages/work/super-gem-heroes.tsx" /* webpackChunkName: "component---src-pages-work-super-gem-heroes-tsx" */),
  "component---src-pages-work-superstar-slots-tsx": () => import("./../../../src/pages/work/superstar-slots.tsx" /* webpackChunkName: "component---src-pages-work-superstar-slots-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

